<template>
	<div v-if="isVisible" class="scroll-to-top" @click="scrollToTop">↑ Top</div>
</template>

<script>
export default {
	data() {
		return {
			isVisible: false, // 버튼 표시 여부
		};
	},
	mounted() {
		// 스크롤 이벤트 리스너 추가
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		// 컴포넌트가 파괴되기 전에 이벤트 리스너 제거
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			// 스크롤 위치가 일정 이상일 때 버튼 표시
			this.isVisible = window.scrollY > 300;
		},
		scrollToTop() {
			// 부드럽게 최상단으로 스크롤
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		},
	},
};
</script>

<style>
.scroll-to-top {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background-color: #007aff;
	color: black;
	padding: 10px 15px;
	border-radius: 5px;
	cursor: pointer;
	transition: opacity 0.3s;
}
.scroll-to-top:hover {
	background-color: #005bb5;
}
</style>
