<template>
	<div class="blueBorder servicecontent__wrapper">
		<div class="blueBorder">
			<div class="servicecontent__textbox">
				<h1>Curious about the pricing?</h1>
				<h3>Find the plan that's right for your business type!</h3>
			</div>
			<div class="card__wrapper--row">
				<div class="TabletOfCard">
					<swiper class="swiper" :options="swiperOption">
						<swiper-slide>
							<div class="card">
								<div class="card_title">
									<h2>Modulus</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">Data Analysis Framework</div>
									<div class="Card_Text">
										A data analysis framework designed with users in mind!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/pricingsecurity"
											>Go to Check Pricing</router-link
										>
									</button>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="card">
								<div class="card_title">
									<h2>BI-Mining</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">Data Visualization Tool</div>
									<div class="Card_Text">
										A compregensive tool for managing<br />
										all essential tasks across your workflow!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/pricingbimining"
											>Go to Check Pricing</router-link
										>
									</button>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="card">
								<div class="card_title">
									<h2>Crypto Schema</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">Protect data securely</div>
									<div class="Card_Text">
										Need a solution that keeps your data safe?
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/solutioncrypto">
											Go to Solution
										</router-link>
									</button>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="card">
								<div class="card_title">
									<h2>FC-Anti Virus</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">Algorithm-Based Vaccine</div>
									<div class="Card_Text">
										A vaccine that leverages machine learning to detect viruses
										more effectively!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/solutionvaccine">
											Go to Solution
										</router-link>
									</button>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="card">
								<div class="card_title">
									<h2>CryptaLedger Tech</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">
										Cryptographic Distributed Ledger Solution
									</div>
									<div class="Card_Text">
										Need a solution to enhance the confidentiality and security
										of transactions?
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/solutionvaccine">
											Go to Solution
										</router-link>
									</button>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
	name: 'slider',
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 6,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
				freeMode: true,
				centerInsufficientSlides: true,
			},
		};
	},
};
</script>

<style>
.swiper {
	margin: 0 40px;
	padding-left: 20px;
}
.swiper-slide {
	width: auto !important;
}

@media (max-width: 1220px) {
	.swiper {
		width: 100%;
		padding: 0;
		margin: 0;
	}
}
</style>
