<template>
	<div class="price_card_box" v-if="computedValue === `ko`">
		<h1>요금이 궁금 하신가요?</h1>
		<h2>내 비지니스 유형에 적합한 요금제를 찾아보세요!</h2>
		<div class="price_card_items">
			<div class="price_card_item">
				<h3>모듈러스</h3>

				<div class="solution_card_text_box">
					<h4>데이터 분석 프레임워크</h4>
					<span>사용자를 생각한 데이터 분석 프레임워크!</span>

					<router-link to="/pricingsecurity" class="price_card_button"
						>요금 확인 바로가기</router-link
					>
				</div>
			</div>
			<div class="price_card_item">
				<h3>BI-Mining</h3>

				<div class="solution_card_text_box">
					<h4>데이터 시각화 툴</h4>
					<span>업무 전반에 걸쳐 필요한 업무 보조 툴을 한 곳에서!</span>
					<router-link to="/pricingbimining" class="price_card_button"
						>요금 확인 바로가기</router-link
					>
				</div>
			</div>
		</div>
		<h1 class="margin-top-200">솔루션이 궁금하신가요?</h1>
		<h2>내 비지니스 유형에 적합한 솔루션을 찾아보세요!</h2>
		<div class="solution_card_items">
			<div class="solution_card_item">
				<h3>암호스키마</h3>

				<div class="solution_card_text_box">
					<h4>데이터를 안전하게 보호</h4>
					<span>데이터를 안전하게 보호하는 솔루션이 필요하다면?</span>

					<router-link to="/solutioncrypto" class="solution_card_button"
						>솔루션 바로가기</router-link
					>
				</div>
			</div>
			<div class="solution_card_item">
				<h3>FC-Anti Virus</h3>

				<div class="solution_card_text_box">
					<h4>알고리즘 전용 백신</h4>
					<span>머신러닝을 활용하여 바이러스를 더욱 잘 잡아내는 백신!</span>
					<router-link to="/solutionvaccine" class="solution_card_button"
						>솔루션 바로가기</router-link
					>
				</div>
			</div>
			<div class="solution_card_item">
				<h3>CryptaLedger Tech</h3>

				<div class="solution_card_text_box">
					<h4>암호화 분산원장 솔루션</h4>
					<span>거래의 기밀성과 안전성을 강화하는 솔루션이 필요하다면?</span>
					<router-link to="/" class="solution_card_button"
						>솔루션 바로가기</router-link
					>
				</div>
			</div>
		</div>
	</div>
	<div class="price_card_box" v-else-if="computedValue === `en`">
		<h1>Curious about the pricing?</h1>
		<h2>Find the plan that's right for your business type!</h2>
		<div class="price_card_items">
			<div class="price_card_item">
				<h3>Modulus</h3>

				<div class="solution_card_text_box">
					<h4>Data Analysis Framework</h4>
					<span>A data analysis framework designed with users in mind!</span>

					<router-link to="/pricingsecurity" class="price_card_button"
						>Go to Check Pricing</router-link
					>
				</div>
			</div>
			<div class="price_card_item">
				<h3>BI-Mining</h3>

				<div class="solution_card_text_box">
					<h4>Data Visualization Tool</h4>
					<span
						>A compregensive tool for managing all essential tasks across your
						workflow!</span
					>
					<router-link to="/pricingbimining" class="price_card_button"
						>Go to Check Pricing</router-link
					>
				</div>
			</div>
		</div>
		<h1 class="margin-top-200">Curious about the solution</h1>
		<h2>Find the plan that's right for your business type!</h2>
		<div class="solution_card_items">
			<div class="solution_card_item">
				<h3>Crypto Schema</h3>

				<div class="solution_card_text_box">
					<h4>Protect data securely</h4>
					<span>Need a solution that keeps your data safe?</span>

					<router-link to="/solutioncrypto" class="solution_card_button"
						>Go to Solution</router-link
					>
				</div>
			</div>
			<div class="solution_card_item">
				<h3>FC-Anti Virus</h3>

				<div class="solution_card_text_box">
					<h4>Algorithm-Based Vaccine</h4>
					<span
						>A vaccine that leverages machine learning to detect viruses more
						effectively!</span
					>
					<router-link to="/solutionvaccine" class="solution_card_button"
						>Go to Solution</router-link
					>
				</div>
			</div>
			<div class="solution_card_item">
				<h3>CryptaLedger Tech</h3>

				<div class="solution_card_text_box">
					<h4>Cryptographic Distributed Ledger Solution</h4>
					<span
						>Need a solution to enhance the confidentiality and security of
						transactions?</span
					>
					<router-link to="/" class="solution_card_button"
						>Go to Solution</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style></style>
