import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/_index.scss';
import scrollanimation from './utils/scrollanimation';
import SwiperKr from './components/SwiperKr.vue';
import SwiperEn from './components/SwiperEn.vue';

Vue.component('SwiperKr', SwiperKr);
Vue.component('SwiperEn', SwiperEn);

Vue.directive('scrollanimation', scrollanimation);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
